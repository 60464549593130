.WorkshopCollapsibleArea {
    padding: 7rem 30px 2rem;
    width: 25%;
    flex: 0 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.WorkshopCollapsibleArea h2 {
    margin-bottom: 2rem;
    font-size: 2.125rem;
}

.WorkshopCollapsibleArea p {
    line-height: 1.3;
    color: #4f575c;
    margin: 1rem 0;
}

.buttonWrapper {
    margin: 10px auto;
    width: 90%;
    display: flex;
    justify-content: center;
}